<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>CLIENTE</b></span></v-toolbar>
      <v-card-text class="pt-0">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pr-1">
              <v-text-field v-model="form_cliente.identificacion" :rules="[rules.requerido, rules.numerico]" v-on:blur="blur_identificacion(form_cliente.identificacion)" :success="isSuccess" maxlength="15" counter="15"  label="No. DE DOCUMENTO *" :color="colores.primario" min="0" autocomplete="off">
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-progress-circular v-if="isloadingIden" size="24" indeterminate></v-progress-circular>
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pr-1">
              <v-autocomplete v-model="form_cliente.documento_id" :rules="[rules.requerido]" :items="items.tipodocumentos" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE DOCUMENTO *"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pr-1">
              <v-autocomplete v-model="form_cliente.tipo_id" :rules="[rules.requerido]" :items="items.tipopersonas" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE PERSONA *"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field v-model="form_cliente.nombre" :rules="[rules.requerido]" :color="colores.primario" label="NOMBRE COMPLETO / RAZÓN SOCIAL *"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3" class="pr-1">
              <v-autocomplete v-model="form_cliente.sexo_id"  :items="items.tiposexos" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="SEXO"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pr-1">
              <v-autocomplete v-model="form_cliente.lgbtiq_id"  :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="PERTENECES AL COLECTIVO LGBTIQ+"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="5">
              <v-autocomplete v-model="form_cliente.especial_id"  :items="items.grupoespecial" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="PERTENECES A UN GRUPO DE CONDICIÓN ESPECIAL"></v-autocomplete>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
import recClientes from '@/js/rec_clientes.js'
export default {
  name: 'solicitanteComponent',
  components: {
  },
  mixins: [recClientes],
  props: ['colores', 'items', 'loadingData', 'rules', 'form_cliente'],
  data: () => ({
    isSuccess: false,
    isloadingIden: false
  }),
  methods: {
    blur_identificacion (pIdentificacion) {
      this.rec_clientes_identificacion(pIdentificacion)
    }
  }
}
</script>
