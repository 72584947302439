import axios from 'axios'
export default {
  methods: {
    rec_clientes_identificacion (pIdentificacion) {
      this.isloadingIden = false
      this.isSuccess = false
      const urlroute = this.$store.getters.getUrl + 'clientes_identificacion/' + pIdentificacion
      // this.loadingBuscar = true
      // this.item_registros = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        if (response.data !== '') {
          this.isSuccess = true
          this.rec_clientes_entidad(response.data)
        } else {
          this.rec_clientes_inicializar()
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.isloadingIden = false
      })
    },
    rec_clientes_entidad (data) {
      this.form_cliente.gestion_id = 0
      this.form_cliente.tipo_id = data.tipo_id
      this.form_cliente.documento_id = data.documento_id
      this.form_cliente.nombre = data.nombre
      this.form_cliente.sexo_id = data.sexo_id
      this.form_cliente.lgbtiq_id = data.lgbtiq_id
      this.form_cliente.especial_id = data.especial_id
    },
    rec_clientes_inicializar () {
      this.form_cliente.gestion_id = 0
      this.form_cliente.tipo_id = 0
      this.form_cliente.documento_id = 0
      this.form_cliente.nombre = ''
      this.form_cliente.sexo_id = null
      this.form_cliente.lgbtiq_id = null
      this.form_cliente.especial_id = null
    }

  }
}
